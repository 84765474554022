/*
 * Copyright (C) 2019-2022 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import PropTypes from "prop-types";

import "./Overview.css";
import { FEATURE_NAMES, MODULES_FEATURES } from "../constants";

Overview.propTypes = {
    configuration: PropTypes.arrayOf(PropTypes.object),
};

export default function Overview({ configuration }) {
    const features = calcConfigurationFeatures(configuration);
    return (
        <div className="overview offset-lg-3 col-lg-6">
            <div className="card">
                <ul className="list-group list-group-flush">
                    {Object.keys(features).map((feature) => (
                        <li key={feature} className="list-group-item">
                            {FEATURE_NAMES[feature]}
                            <span className="badge bg-primary float-end">
                                {features[feature]}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

function calcConfigurationFeatures(configuration) {
    return configuration.reduce((acc, module) => {
        addModuleFeatures(acc, module.name);
        if (!Object.prototype.hasOwnProperty.call(module, "addon")) return acc;
        addModuleFeatures(acc, module.addon.name);
        return acc;
    }, {});
}

function addModuleFeatures(acc, moduleName) {
    const features = MODULES_FEATURES[moduleName];
    Object.keys(features).forEach((feature) => {
        if (Object.prototype.hasOwnProperty.call(acc, feature))
            acc[feature] += features[feature];
        else acc[feature] = features[feature];
    });
    return acc;
}
