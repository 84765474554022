/*
 * Copyright (C) 2019-2024 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import { useEffect, useState } from "react";

import { DragDropContext } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";

import Alert from "../components/Alert";
import KitsBar from "../components/KitsBar";
import SelectBar from "./SelectBar";
import Configuration from "./Configuration";
import Overview from "./Overview";
import {
    addAddon,
    addModule,
    checkConfiguration,
    configurationToString,
    moveModule,
    removeAddon,
    removeModule,
    stringToConfiguration,
} from "../configurationHelpers";
import { ADDONS, INITIAL_CONFIGURATION } from "../constants";

function MoxConfigurator() {
    const [configuration, setConfiguration] = useState(
        stringToConfiguration(INITIAL_CONFIGURATION)
    );
    const [alert, setAlert] = useState(false);
    const [currentDraggable, setCurrentDraggable] = useState(null);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.configuration) {
            const newConfiguration = stringToConfiguration(
                params.configuration
            );
            if (checkConfiguration(newConfiguration)) {
                setConfiguration(newConfiguration);
                setAlert(false);
            } else {
                setAlert(true);
                setConfiguration(stringToConfiguration(INITIAL_CONFIGURATION));
            }
        }
    }, [params.configuration, navigate]);

    function checkAndApply(action, result) {
        const newConfiguration = action(result);
        if (newConfiguration && checkConfiguration(newConfiguration)) {
            setAlert(false);
            setConfiguration(newConfiguration);
            navigate(`/${configurationToString(newConfiguration)}`);
        } else setAlert(true);
    }

    function moveConfigurationModule(result) {
        const { destination, source } = result;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return configuration;
        return moveModule(configuration, source.index, destination.index);
    }

    function addModuleToConfiguration(result) {
        const { destination, draggableId } = result;
        if (ADDONS.indexOf(draggableId) === -1)
            return addModule(configuration, draggableId, destination.index);
    }

    function removeModuleFromConfiguration(result) {
        return removeModule(configuration, result.source.index);
    }

    function addAddonToConfiguration(result) {
        const { draggableId, combine } = result;
        if (ADDONS.indexOf(draggableId) !== -1)
            return addAddon(configuration, combine.draggableId, draggableId);
    }

    function onDragEnd(result) {
        setCurrentDraggable(null);

        if (result.combine) {
            if (ADDONS.indexOf(result.draggableId) !== -1)
                checkAndApply(addAddonToConfiguration, result);
        } else if (result.destination) {
            const sourceId = result.source.droppableId;
            const destinationId = result.destination.droppableId;
            if (destinationId === "configuration") {
                if (sourceId === "configuration")
                    checkAndApply(moveConfigurationModule, result);
                else if (sourceId === "select-bar") {
                    checkAndApply(addModuleToConfiguration, result);
                }
            } else if (destinationId === "select-bar") {
                if (sourceId === "configuration")
                    checkAndApply(removeModuleFromConfiguration, result);
            }
        }
    }

    function onDragStart(result) {
        setCurrentDraggable(result.draggableId);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <div className="container">
                <h1>Turris MOX Configurator</h1>
                <h2>Available Modules</h2>
            </div>
            <SelectBar configuration={configuration} />
            <div className="container">
                <h2>Your Configuration</h2>
                {alert ? <Alert onDismiss={() => setAlert(false)} /> : null}
                <KitsBar
                    setConfiguration={(configuration) => {
                        setConfiguration(configuration);
                        setAlert(false);
                    }}
                />
            </div>
            <Configuration
                configuration={configuration}
                currentDraggable={currentDraggable}
                removeAddon={(moduleId) => {
                    setConfiguration(removeAddon(configuration, moduleId));
                    setAlert(false);
                }}
                removeModule={(index) => {
                    setConfiguration(removeModule(configuration, index));
                    setAlert(false);
                }}
            />
            <div className="container">
                <h2>Features Overview</h2>
                <Overview configuration={configuration} />
            </div>
        </DragDropContext>
    );
}

export default MoxConfigurator;
