/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import { useState } from "react";
import PropTypes from "prop-types";

import "./Addon.css";
import { getModuleImageName } from "../utils";

Addon.propTypes = {
    name: PropTypes.string.isRequired,
    removeAddon: PropTypes.func.isRequired,
};

export default function Addon({ name, removeAddon }) {
    const [hover, setHover] = useState(false);
    return (
        <>
            <img
                className={`addon addon-${name}`}
                src={getModuleImageName(name)}
                alt={name}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            />
            {hover ? (
                <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={removeAddon}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    &#10006;
                </button>
            ) : null}
        </>
    );
}
