import { createRoot } from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-roboto";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./App.css";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
