/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import "./SelectBar.css";
import { Droppable } from "react-beautiful-dnd";

import ModuleToSelect from "../components/ModuleToSelect";
import {
    checkConfiguration,
    configurationToString,
} from "../configurationHelpers";
import PropTypes from "prop-types";

const MODULES_NAMES = ["B", "C", "D", "E", "F", "G", "P", "W", "X"];

SelectBar.propTypes = {
    configuration: PropTypes.arrayOf(PropTypes.object),
};

export default function SelectBar({ configuration }) {
    const listOfPossibleModules = getListOfPossibleModules(configuration);
    return (
        <Droppable
            droppableId="select-bar"
            direction="horizontal"
            isDropDisabled
        >
            {(provided) => (
                <div
                    id="modules-select-bar"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {MODULES_NAMES.map((moduleName, idx) => (
                        <ModuleToSelect
                            key={moduleName}
                            name={moduleName}
                            index={idx}
                            isInactive={
                                !listOfPossibleModules.includes(moduleName)
                            }
                        />
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}

function getListOfPossibleModules(configuration) {
    const strConfiguration = configurationToString(configuration);

    return MODULES_NAMES.map((module) => {
        for (let i = 0; i <= strConfiguration.length + 1; i++) {
            const newConfiguration = [
                strConfiguration.slice(0, i),
                module,
                strConfiguration.slice(i),
            ].join("");
            if (checkConfiguration(newConfiguration)) return module;
        }
        return null;
    }).filter(Boolean);
}
