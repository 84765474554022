/*
 * Copyright (C) 2019-2023 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import PropTypes from "prop-types";

import "./Module.css";
import "../Addon.css";
import { getModuleImageName } from "../../utils";
import { MODULES_FEATURES } from "../../constants";
import BusSVG from "./BusSvg";

Module.propTypes = {
    name: PropTypes.string.isRequired,
    zIndex: PropTypes.number.isRequired,
    showAddonPlaceholder: PropTypes.bool,
    currentDraggable: PropTypes.string,
};

Module.defaultProps = {
    zIndex: 1,
};

export default function Module({
    name,
    zIndex,
    showAddonPlaceholder,
    currentDraggable,
    ...props
}) {
    return (
        <div style={{ zIndex: zIndex }}>
            <BusSVG name={name} />
            {showAddonPlaceholder ? (
                <AddonPlaceholder
                    name={name}
                    currentDraggable={currentDraggable}
                />
            ) : null}
            <img src={getModuleImageName(name)} alt={name} {...props} />
        </div>
    );
}

AddonPlaceholder.propTypes = {
    name: PropTypes.string.isRequired,
    currentDraggable: PropTypes.string.isRequired,
};

function AddonPlaceholder({ name, currentDraggable }) {
    if (Object.prototype.hasOwnProperty.call(MODULES_FEATURES[name], "mpci"))
        return (
            <img
                className={`addon addon-placeholder addon-${currentDraggable}`}
                alt={name}
                src={getModuleImageName(currentDraggable)}
            />
        );
    if (name === "A")
        return (
            <img
                className={`addon addon-placeholder addon-${currentDraggable}`}
                alt={name}
                src={getModuleImageName(currentDraggable)}
            />
        );
    return null;
}
