/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import PropTypes from "prop-types";

const LINE_WIDTH = 15;
const SGMII_COLOR = "#B8923B";
const PCIE_COLOR = "#A67FB3";
const SGMII_LINE_STYLE = { stroke: SGMII_COLOR, strokeWidth: LINE_WIDTH };
const PCIE_LINE_STYLE = { stroke: PCIE_COLOR, strokeWidth: LINE_WIDTH };

BusSVG.propTypes = {
    name: PropTypes.string.isRequired,
};

export default function BusSVG({ name }) {
    let svg;

    switch (name) {
        case "A":
            svg = (
                <svg viewBox="0 0 206 400">
                    <line
                        x1="100"
                        y1="95"
                        x2="206"
                        y2="95"
                        style={SGMII_LINE_STYLE}
                    />
                    <text x="120" y="101" fill="white">
                        SGMII
                    </text>
                    <line
                        x1="100"
                        y1="140"
                        x2="206"
                        y2="140"
                        style={PCIE_LINE_STYLE}
                    />
                    <text x="125" y="146" fill="white">
                        PCIe
                    </text>
                    <text x="70" y="265" fontSize="100" fill="white">
                        A
                    </text>
                </svg>
            );
            break;
        case "B":
            svg = (
                <svg viewBox="0 0 203 400">
                    <line
                        x1="0"
                        y1="95"
                        x2="206"
                        y2="95"
                        style={SGMII_LINE_STYLE}
                    />
                    <line
                        x1="0"
                        y1="140"
                        x2="108"
                        y2="140"
                        style={PCIE_LINE_STYLE}
                    />
                    <line
                        x1="100"
                        y1="140"
                        x2="100"
                        y2="200"
                        style={PCIE_LINE_STYLE}
                    />
                    <text x="75" y="265" fontSize="100" fill="white">
                        B
                    </text>
                </svg>
            );
            break;
        case "C":
            svg = (
                <svg viewBox="0 0 206 400">
                    <line
                        x1="0"
                        y1="95"
                        x2="118"
                        y2="95"
                        style={SGMII_LINE_STYLE}
                    />
                    <line
                        x1="110"
                        y1="95"
                        x2="110"
                        y2="200"
                        style={SGMII_LINE_STYLE}
                    />
                    <text x="75" y="265" fontSize="100" fill="white">
                        C
                    </text>
                </svg>
            );
            break;
        case "D":
            svg = (
                <svg viewBox="0 0 204 400">
                    <line
                        x1="0"
                        y1="95"
                        x2="117"
                        y2="95"
                        style={SGMII_LINE_STYLE}
                    />
                    <line
                        x1="110"
                        y1="95"
                        x2="110"
                        y2="200"
                        style={SGMII_LINE_STYLE}
                    />
                    <text x="75" y="265" fontSize="100" fill="white">
                        D
                    </text>
                </svg>
            );
            break;
        case "E":
            svg = (
                <svg viewBox="0 0 388 400">
                    <line
                        x1="0"
                        y1="95"
                        x2="388"
                        y2="95"
                        style={SGMII_LINE_STYLE}
                    />
                    <line
                        x1="200"
                        y1="95"
                        x2="200"
                        y2="200"
                        style={SGMII_LINE_STYLE}
                    />
                    <text x="170" y="265" fontSize="100" fill="white">
                        E
                    </text>
                </svg>
            );
            break;
        case "F":
            svg = (
                <svg viewBox="0 0 204 400">
                    <line
                        x1="0"
                        y1="95"
                        x2="204"
                        y2="95"
                        style={SGMII_LINE_STYLE}
                    />
                    <line
                        x1="0"
                        y1="140"
                        x2="108"
                        y2="140"
                        style={PCIE_LINE_STYLE}
                    />
                    <line
                        x1="100"
                        y1="140"
                        x2="100"
                        y2="200"
                        style={PCIE_LINE_STYLE}
                    />
                    <text x="75" y="265" fontSize="100" fill="white">
                        F
                    </text>
                </svg>
            );
            break;
        case "G":
            svg = (
                <svg viewBox="0 0 204 400">
                    <line
                        x1="0"
                        y1="95"
                        x2="204"
                        y2="95"
                        style={SGMII_LINE_STYLE}
                    />
                    <line
                        x1="0"
                        y1="140"
                        x2="204"
                        y2="140"
                        style={PCIE_LINE_STYLE}
                    />
                    <line
                        x1="100"
                        y1="140"
                        x2="100"
                        y2="200"
                        style={PCIE_LINE_STYLE}
                    />
                    <text x="65" y="265" fontSize="100" fill="white">
                        G
                    </text>
                </svg>
            );
            break;
        default:
            svg = null;
    }

    if (!svg) return null;

    return <div className="module-buses">{svg}</div>;
}
