/*
 * Copyright (C) 2019-2024 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import PropTypes from "prop-types";

import { KITS } from "../constants";
import "./KitsBar.css";
import { stringToConfiguration } from "../configurationHelpers";
import { useNavigate, useParams } from "react-router-dom";

KitsBar.propTypes = {
    setConfiguration: PropTypes.func.isRequired,
};

export default function KitsBar({ setConfiguration }) {
    const navigate = useNavigate();
    const params = useParams();

    return (
        <div className="kits-bar">
            {KITS.map((kit) => (
                <button
                    key={kit.name}
                    className={`btn btn-outline-primary btn-sm ${
                        kit.configuration === params.configuration
                            ? "active"
                            : ""
                    }`}
                    onClick={() => {
                        setConfiguration(
                            stringToConfiguration(kit.configuration)
                        );
                        navigate(`/${kit.configuration}`);
                    }}
                >
                    {kit.name}
                </button>
            ))}
        </div>
    );
}
