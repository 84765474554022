/*
 * Copyright (C) 2019-2022 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

/*global require */
/*eslint no-undef: "error"*/
/*eslint no-redeclare: "off"*/

// Import all modules images.

function importAll(r) {
    return r.keys().reduce((imgs, img) => {
        imgs[img] = r(img);
        return imgs;
    }, {});
}

const images = importAll(require.context("./imgs/modules", false, /\.png$/));

export function getModuleImageName(moduleName) {
    return images[`./${moduleName}.png`];
}
