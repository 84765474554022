/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";

import "./Configuration.css";
import ModuleInConfiguration from "../components/ModuleInConfiguration";
import Addon from "../components/Addon";

Configuration.propTypes = {
    configuration: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentDraggable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    removeModule: PropTypes.func.isRequired,
    removeAddon: PropTypes.func.isRequired,
};

export default function Configuration({
    configuration,
    currentDraggable,
    removeModule,
    removeAddon,
}) {
    return (
        <Droppable
            droppableId="configuration"
            direction="horizontal"
            isCombineEnabled
        >
            {(provided) => (
                <div
                    id="configuration"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {[...configuration].map((module, index) => (
                        <ModuleInConfiguration
                            key={module.id}
                            id={module.id}
                            index={index}
                            name={module.name}
                            currentDraggable={currentDraggable}
                            removeModule={() => removeModule(index)}
                        >
                            {module.addon ? (
                                <Addon
                                    name={module.addon.name}
                                    removeAddon={() => removeAddon(module.id)}
                                />
                            ) : null}
                        </ModuleInConfiguration>
                    ))}
                    {provided.placeholder && (
                        <div style={{ width: 0 }}>{provided.placeholder}</div>
                    )}
                </div>
            )}
        </Droppable>
    );
}
