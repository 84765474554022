/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 *
 */

export const KITS = [
    {
        name: "Start",
        configuration: "A",
    },
    {
        name: "Pocket Wi-Fi",
        configuration: "AS",
    },
    {
        name: "Power Wi-Fi",
        configuration: "ASBW",
    },
    {
        name: "Power Wi-Fi 6",
        configuration: "ABX",
    },
    {
        name: "Classic",
        configuration: "ASBWC",
    },
    {
        name: "Classic Wi-Fi 6",
        configuration: "ABXC",
    },
    {
        name: "MOOOOOOOX",
        configuration: "PASGWBWEEED",
    },
];

export const ADDONS = ["S", "W", "X"];
export const INITIAL_CONFIGURATION = KITS[0].configuration;

export const MODULES_DESCRIPTION = {
    A: { short: "", long: "" },
    B: { short: "mPCIe", long: "" },
    C: { short: "4xRJ-45", long: "" },
    D: { short: "SFP", long: "" },
    E: { short: "8xRJ-45", long: "" },
    F: { short: "4xUSB 3.0", long: "" },
    G: { short: "mPCIe", long: "" },

    P: { short: "PoE", long: "" },
    S: { short: "Wi-Fi 5,<br>2x2", long: "" },
    W: { short: "Wi-Fi 5,<br>3x3", long: "" },
    X: { short: "Wi-Fi 6,<br>DBDC<br>2x2", long: "" },
};

export const FEATURE_NAMES = {
    rj45: "RJ-45",
    mpci: "mPCIe",
    sfp: "SFP",
    usb3: "USB3.0",
    poe: "PoE",
    wifi: "Wi-Fi",
    sdio2x2: "SDIO Wi-Fi 5 (2x2)",
    mpci3x3: "miniPCIe Wi-Fi 5 (3x3)",
    mpci2x2: "miniPCIe Wi-Fi 6 (DBDC 2x2)",
};

export const MODULES_FEATURES = {
    A: { rj45: 1, usb3: 1 },
    B: { mpci: 1 },
    C: { rj45: 4 },
    D: { sfp: 1 },
    E: { rj45: 8 },
    F: { usb3: 4 },
    G: { mpci: 1 },

    P: { poe: 1 },
    S: { wifi: 1, sdio2x2: 1 },
    W: { wifi: 1, mpci3x3: 1 },
    X: { wifi: 1, mpci2x2: 1 },
};
