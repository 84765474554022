/*
 * Copyright (C) 2019-2024 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import PropTypes from "prop-types";

Alert.propTypes = {
    onDismiss: PropTypes.func.isRequired,
};

export default function Alert({ onDismiss }) {
    return (
        <div className="alert alert-dismissible alert-danger" role="alert">
            {onDismiss && (
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={onDismiss}
                ></button>
            )}
            <h4 className="alert-heading">
                The configuration was not correct!
            </h4>
            <p>
                Please choose a valid configuration from the kits below or
                create your own by adding modules and addons to the correct
                slots.
            </p>
            <hr />
            <p className="mb-0">
                <small>
                    Hint: Follow PCIe and SGMII connector lines for the correct
                    configuration.
                </small>
            </p>
        </div>
    );
}
