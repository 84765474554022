/*
 * Copyright (C) 2019-2023 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 */

import { useState } from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";

import "./ModuleInConfiguration.css";
import Module from "./Module/Module";

ModuleInConfiguration.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    removeModule: PropTypes.func.isRequired,
    currentDraggable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node,
};

export default function ModuleInConfiguration({
    name,
    index,
    id,
    removeModule,
    currentDraggable,
    children,
}) {
    const [hover, setHover] = useState(false);

    function showAddonPlaceholder() {
        if (name === "A" && currentDraggable === "S") return true;

        return (
            ["B", "G"].includes(name) &&
            (currentDraggable === "W" || currentDraggable === "X")
        );
    }

    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={`module module-${name}`}
                >
                    {hover && name !== "A" ? (
                        <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={removeModule}
                        >
                            &#10006;
                        </button>
                    ) : null}
                    {children}
                    <Module
                        name={name}
                        zIndex={-index}
                        showAddonPlaceholder={showAddonPlaceholder()}
                        currentDraggable={currentDraggable}
                    />
                </div>
            )}
        </Draggable>
    );
}
