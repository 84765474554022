/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import PropTypes from "prop-types";

import { Draggable } from "react-beautiful-dnd";
import { MODULES_DESCRIPTION } from "../constants";
import Module from "./Module/Module";

ModuleToSelect.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isInactive: PropTypes.bool,
};

export default function ModuleToSelect({ name, index, isInactive }) {
    return (
        <Draggable draggableId={name} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`module module-${name} ${
                        isInactive ? "module-inactive" : ""
                    }`}
                >
                    <Module name={name} />
                    <ModuleDescription name={name} />
                </div>
            )}
        </Draggable>
    );
}

ModuleDescription.propTypes = {
    name: PropTypes.string.isRequired,
};

function ModuleDescription({ name }) {
    return (
        <p
            dangerouslySetInnerHTML={{
                __html: MODULES_DESCRIPTION[name].short,
            }}
            style={{ textAlign: "center" }}
        />
    );
}
