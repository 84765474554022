/*
 * Copyright (C) 2019 CZ.NIC z.s.p.o. (http://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import update from "immutability-helper";
import { ADDONS } from "./constants";

export const VALIDATION_RE = "^P?AS?(GX|GW?)?(BX|BW?|F)?E{0,2}(C|D|E|ED)?$";

let idCounter = 10;

export function addModule(configuration, name, index) {
    return insertNewModule(
        configuration,
        { name: name, id: getNewId() },
        index
    );
}

function getNewId() {
    idCounter++;
    return idCounter.toString();
}

export function insertNewModule(configuration, module, index) {
    return update(configuration, { $splice: [[index, 0, module]] });
}

export function removeModule(configuration, index) {
    return update(configuration, { $splice: [[index, 1]] });
}

export function moveModule(configuration, index, atIndex) {
    const module = configuration[index];
    return update(configuration, {
        $splice: [
            [index, 1],
            [atIndex, 0, module],
        ],
    });
}

export function checkConfiguration(configuration) {
    let configurationToCheck = configuration;
    if (typeof configuration !== "string")
        configurationToCheck = configurationToString(configuration);
    return !!configurationToCheck.match(VALIDATION_RE);
}

export function addAddon(configuration, moduleId, addonName) {
    const indexOfModule = configuration.findIndex(
        (module) => module.id === moduleId
    );
    const module = configuration[indexOfModule];
    const addon = { name: addonName, id: getNewId() };
    return update(configuration, {
        $splice: [
            [indexOfModule, 1],
            [indexOfModule, 0, { ...module, addon: addon }],
        ],
    });
}

export function removeAddon(configuration, moduleId) {
    const indexOfModule = configuration.findIndex(
        (module) => module.id === moduleId
    );
    const module = configuration[indexOfModule];
    return update(configuration, {
        $splice: [
            [indexOfModule, 1],
            [indexOfModule, 0, { name: module.name, id: module.id }],
        ],
    });
}

export function configurationToString(configuration) {
    return configuration
        .map(
            (module) => `${module.name}${module.addon ? module.addon.name : ""}`
        )
        .join("");
}

export function stringToConfiguration(configurationStr) {
    let configuration = [];
    configurationStr.split("").forEach((module) => {
        if (ADDONS.includes(module))
            configuration[configuration.length - 1].addon = {
                name: module,
                id: getNewId(),
            };
        else configuration.push({ name: module, id: getNewId() });
    });
    return configuration;
}
