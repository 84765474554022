/*
 * Copyright (C) 2019-2022 CZ.NIC z.s.p.o. (https://www.nic.cz/)
 *
 * This is free software, licensed under the GNU General Public License v3.
 * See /LICENSE for more information.
 *
 */

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

import MoxConfigurator from "./containers/MoxConfigurator";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/:configuration" element={<MoxConfigurator />} />
                <Route path="*" element={<Navigate to="/A" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
